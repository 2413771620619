import React, { useEffect, useState, useContext, useCallback } from 'react';
import { ThemeProvider, createMuiTheme } from '@material-ui/core';

import commonStyles from '../../commonUtils/commonStyles';
import MainView from './MainView';
import CustomerDetailsView from './CustomerDetailsView';
import useServerRequest from '../../hooks/serverRequest';
import { GlobalContext } from '../..'
import { API_ENDPOINTS_LIST } from '../../constants';

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: commonStyles.color.authError,
    },
    primary: {
      main: commonStyles.color.blue,
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

const CustomerManagement = () => {
  const { setGlobalLoading, setToastState } = useContext(GlobalContext);
  const { getRequest } = useServerRequest(setGlobalLoading)

  const [screenType, setScreenType] = useState('MainView');
  const [customers, setCustomers] = useState();
  const [additionalData, setAdditionalData] = useState()

  const setToast = (res) => {
    setToastState((prevState) => ({
      ...prevState,
      toastOpen: true,
      toastMessage: res.message,
      toastStyle: 'error',
    }));
  }

  const getCustomersAndAdditionalData = useCallback(()=>{
    getRequest(API_ENDPOINTS_LIST.get_customer, '', 'GET').then((res)=>{
      if(res && !res.code) {
        setCustomers(res.result)
      }
      else{
        setToast(res)
      }
    })
    getRequest(API_ENDPOINTS_LIST.get_additionalData, '', 'GET').then((res)=>{
      if(res && !res.code) {
        setAdditionalData(res.result)
      }
      else{
        setToast(res)
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(()=>{
    getCustomersAndAdditionalData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const [customer, setCustomer] = useState(null);

  const onClickCustomer = (id) => {
    const filteredCustomer = customers.find((value) => value.id === id);
    setCustomer(filteredCustomer);
    setScreenType('CustomerDetailsView');
  };

  const setNewDataCustomer = (value, field, valueIsObject = false) => {
    const newData = valueIsObject ? { ...customer, ...value } : { ...customer, [field]: value };
    setCustomer(newData);
  };

  const goToMain = () => {
    getCustomersAndAdditionalData()
    setScreenType('MainView')
  }

  return (
    <ThemeProvider theme={theme}>
      {screenType === 'MainView' ? (
        <MainView goToDetails={onClickCustomer} customers={customers} additionalData={additionalData}/>
      ) : (
        <CustomerDetailsView customer={customer} setNewDataCustomer={setNewDataCustomer} goToMain={goToMain} consultants={additionalData.consultants}/>
      )}
    </ThemeProvider>
  );
};

export default CustomerManagement;
