import React, { Fragment } from 'react';
import { Grid, Typography } from '@material-ui/core';

import commonStyles from '../../../../commonUtils/commonStyles';
import TextInput from '../../../Components/Elements/TextInput/TextInputComponent';
import DropDown from '../../../Components/Elements/DropDown/DropDownComponent';

import Buttons from './ButtonsInfoTab';
import { inputs } from './InputList';

const InfoDetailInputs = ({ userData, selectValues, onChangeInput, onChange, onSave, onCancel, rowName, error, consultants }) => {
  const { title } = getStyles();

  const getValue = (field, userData) => {
    switch (field) {
      case 'fullName':
        return `${userData.lastName} ${userData.firstName} ${userData.middleName}`;
      case 'legalLocation':
      case 'individualLocation':
        const arr = [];
        for (const key in userData[field]) {
          arr.push(userData[field][key]);
        }
        return arr.join(', ');
      default:
        return userData[field];
    }
  };

  const getIndexConsultants = (consultantId, consultants) => {
    const consultantsIds = consultants.map(consultant => consultant.id)
    return consultantsIds.indexOf(consultantId)
  }

  const getDefaultSelectValue = (field, values, userData) => {
    switch(field){
      case 'engineer':
        return getIndexConsultants(userData['consultantId'], consultants)
      default:
        return userData[field] === true ? 1 : 0
    }
  }

  const onChangeSelect = (value, field) => {
    switch(field){
      case 'engineer':
        onChangeInput(consultants[Number(value)].id, 'consultantId')
        break;
      default:
        onChangeInput(value === '1' ? true : false, field)
        break;
    }
  }

  const getInput = ({ typeInput, name, index, valueType, type, disabled }) => {
    switch (typeInput) {
      case 'textInput':
        return (
          <TextInput
            value={getValue(name, userData) ?? ''}
            disabled={disabled ?? rowName !== name}
            onChange={(e) => onChangeInput(e.target.value, name)}
            tabIndex={index}
            id={name}
            value_type={valueType ? valueType : 'only_value'}
            input_type={type}
            inputProps={type === 'number' ? { min: '0' } : {}}
            fieldIncorrect={Boolean(error)}
            helperText={rowName === name ? error : ''}
          />
        );
      case 'selectInput':
        return (
          <DropDown
            values={selectValues[name]}
            disabled={rowName !== name}
            onChange={(e) => onChangeSelect(e.target.value, name)}
            tabIndex={index}
            id={name}
            selValue={getDefaultSelectValue(name, selectValues[name], userData)}
          />
        );
      default:
        return null;
    }
  };

  return inputs.map((input, index) => (
    <Fragment key={input.name}>
      <Grid item xs={12} sm={12} md={3}>
        <Typography style={{ ...title, ...commonStyles.text.robotoRegular18Light }}>{input.title}</Typography>
      </Grid>
      <Grid item xs={12} sm={7} md={6}>
        {getInput({
          typeInput: input.typeInput,
          name: input.name,
          index,
          valueType: input.valueType,
          type: input.type,
          disabled: input.disabled,
        })}
      </Grid>
      <Grid item xs={12} sm={5} md={3}>
        <Buttons
          name={input.name}
          value={rowName}
          onChange={onChange}
          onCancel={onCancel}
          onSave={onSave}
          disabled={input.disabled}
        />
      </Grid>
    </Fragment>
  ));
};

const getStyles = () => {
  return {
    title: {
      lineHeight: '46px',
    },
  };
};

export default InfoDetailInputs;
