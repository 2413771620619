import React, { useState, useEffect, createContext } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import './index.css';
import 'typeface-roboto';
import HttpsRedirect from 'react-https-redirect';
//import jwt_decode from 'jwt-decode';
//import reportWebVitals from './reportWebVitals';

//common styles
import commonStyles from './commonUtils/commonStyles';

import MainClass from './main/MainClass';

import { BASE_URL, ROUTES_LIST, TOAST_STATE } from './constants';
import { isValidToken, resetToken } from './commonUtils/commonUtils';

import LoaderComponent from './screens/Components/LoaderComponent/LoaderComponent';
import ToastComponent from './screens/Components/ToastComponent/ToastComponent';

import PrivateRoute from './screens/Components/PrivateRoute';

import AuthScreen from './screens/AuthScreen/AuthScreen';
import CatalogsScreen from './screens/CatalogsScreen/CatalogsScreen';
import DocumentsScreen from './screens/DocumentsScreen/DocumentsScreen';
import CustomerManagementScreen from './screens/CustomerManagementScreen/CustomerManagementScreen';

export const GlobalContext = createContext(null);

const App = () => {
  //console.log('BASE_URL: ' + BASE_URL);
  MainClass.init();
  document.body.style.background = commonStyles.color.background;

  //Tokens
  const existingTokens = JSON.parse(localStorage.getItem('tokens'));
  //console.log('existingTokens: ' + JSON.stringify(existingTokens));

  let checkedToken = '';

  if (existingTokens !== null) {
    checkedToken = isValidToken(existingTokens.tokenTimeStamp) ? existingTokens : '';
  }

  const setTokens = (data) => {
    if (data) {
      localStorage.setItem('tokens', JSON.stringify(data));
    } else {
      localStorage.removeItem('tokens');
    }
    setAuthTokens(data);
  };

  useEffect(() => {
    if (checkedToken === '') {
      resetToken(setToastState);
    }
  }, [checkedToken]);

  //Setup Global Context params
  const [authTokens, setAuthTokens] = useState(checkedToken);
  const [globalLoading, setGlobalLoading] = useState(false);
  const [toastState, setToastState] = useState(TOAST_STATE);

  //resetToken(setToastState);

  //Handlers
  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastState((prevState) => ({
      ...prevState,
      toastOpen: false,
    }));
  };

  //Routes
  const userRoutes = (
    <Switch>
      {!checkedToken && (
        <Route exact path="/">
          <AuthScreen />
        </Route>
      )}
      <PrivateRoute exact path="/">
        <CustomerManagementScreen />
      </PrivateRoute>
      <PrivateRoute exact path={ROUTES_LIST.catalogs}>
        <CatalogsScreen />
      </PrivateRoute>
      <PrivateRoute exact path={ROUTES_LIST.documents}>
        <DocumentsScreen />
      </PrivateRoute>
      <Redirect path="*" to="/" />
    </Switch>
  );

  return (
    <GlobalContext.Provider
      value={{
        authTokens,
        setAuthTokens: setTokens,
        globalLoading,
        setGlobalLoading,
        toastState,
        setToastState,
      }}
    >
      <LoaderComponent loading={globalLoading} />
      <ToastComponent
        open={toastState.toastOpen}
        close={handleToastClose}
        duration={toastState.toastAutohideDuration}
        styles={toastState.toastStyle}
        message={toastState.toastMessage}
      />
      <Router>{userRoutes}</Router>
    </GlobalContext.Provider>
  );
};

ReactDOM.render(
  <HttpsRedirect>
    <App />
  </HttpsRedirect>,
  document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
