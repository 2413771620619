import React from 'react';
import { Box } from '@material-ui/core';

import Button from '../../../Components/Elements/Button/ButtonComponent';

const ButtonsInfoTab = ({ value, name, onChange, onSave, onCancel, disabled = false }) => {
  const { root, button, change, cancel, save } = getStyles();

  return (
    <Box style={root}>
      {value === '' && (
        <Button variant="contained" style={{ ...button, ...change }} onClick={() => onChange(name)}>
          Изменить
        </Button>
      )}
      {value === name && !disabled && (
        <>
          <Button variant="outlined" style={{ ...button, ...cancel }} onClick={() => onCancel(name)}>
            Отмена
          </Button>
          <Button variant="contained" style={{ ...button, ...save }} onClick={() => onSave(name)}>
            Сохранить
          </Button>
        </>
      )}
    </Box>
  );
};

const getStyles = () => {
  return {
    root: {
      display: 'flex',
      justifyContent: 'flex-end',
      height: 46,
    },
    button: {
      height: 46,
    },
    change: {
      width: 172,
    },
    cancel: {
      marginRight: 10,
      width: 110,
    },
    save: {
      width: 172,
    },
  };
};

export default ButtonsInfoTab;
