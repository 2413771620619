import React from 'react';
import { Box, Container } from '@material-ui/core';

const TabPanel = ({ children, id, value }) => {
  const { root, container } = getStyles();

  return (
    <Box role="tabpanel" id={id} hidden={value !== id} style={root}>
      <Container style={container}>{children}</Container>
    </Box>
  );
};

const getStyles = () => {
  return {
    root: {
      marginTop: 20,
      marginBottom: 20,
    },
    container: {
      maxWidth: 1394,
    },
  };
};

export default TabPanel;
