import React, { useEffect, useState, useContext } from 'react';

import FilterObject from '../FilterObject';
import ShowFileBox from '../ShowFileBox';
import SettlementList from './SettlementList';
import useServerRequest from '../../../../hooks/serverRequest';
import { GlobalContext } from '../../../..'
import { API_ENDPOINTS_LIST, BASE_URL } from '../../../../constants'

const SettlementsTab = ({customerId}) => {
  const [filterValues, setFilterValues] = useState(['Не выбрано']);
  const [filterValue, setFilterValue] = useState(filterValues[0]);
  const [selectedIds, setSelectedId] = useState([]);
  const [data, setData] = useState();
  const [filteredData, setFilteredData] = useState(null);
  const { setGlobalLoading, setToastState } = useContext(GlobalContext);
  const { getRequest } = useServerRequest(setGlobalLoading)
  const { getRequest: pdfRequest } = useServerRequest(setGlobalLoading, true)

  const getToast = (message) => {
    setToastState((prevState) => ({
      ...prevState,
      toastOpen: true,
      toastMessage: message,
      toastStyle: 'error',
    }));
  }

  useEffect(()=>{
    getRequest(`${API_ENDPOINTS_LIST.get_stores}/${customerId}`, '', 'GET').then((res)=>{
      if(res && !res.code){
        setData(res.result)
        setFilterValues((prevState)=>[...prevState, ...res.result.map((row)=> row.nameOfObject)])
      }
      else{
        getToast(res.message)
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const onClickFilter = () => {
    let newData;
    if (filterValue === filterValues[0]) {
      newData = null;
    } else {
      newData = data.filter((value) => value.nameOfObject === filterValue);
    }
    setFilteredData(newData);
    setSelectedId([])
  };

  const onClickShowSettlement = () => {
    if(selectedIds.length !== 0){
      pdfRequest(API_ENDPOINTS_LIST.post_token_store, JSON.stringify(selectedIds), 'POST').then((res)=>{
        if(res && !res.code){
          const pdfToken = res.result.token;
          const downloadlink = pdfToken && `${BASE_URL}${API_ENDPOINTS_LIST.get_view_store}/${pdfToken}/`;
          window.open(downloadlink)
        }
        else{
          getToast(res.message)
        }
      })
    }
    else{
      getToast('Выберите необходимые рассчеты')
    }
  };

  const onChangeId = (id) => {
    if (selectedIds.indexOf(id) !== -1) {
      setSelectedId(selectedIds.filter((n) => n !== id))
    } else {
      const newArray = [...selectedIds, id]
      setSelectedId(newArray)
    }
  }

  return data && filterValues ? (
    <>
      <FilterObject values={filterValues} onChange={setFilterValue} onClick={onClickFilter} />
      <ShowFileBox buttonText="Показать расчет" onClick={onClickShowSettlement} />
      <SettlementList data={filteredData ?? data} selectedIds={selectedIds} onChange={onChangeId} />
    </>
  ): null;
};

export default SettlementsTab;
