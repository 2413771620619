import React, { useState } from 'react';
import { Box, Paper, Tabs, Tab } from '@material-ui/core';

import commonStyles from '../../commonUtils/commonStyles';

import HeaderComponent from '../Components/HeaderComponent/HeaderComponent';
import TabPanel from './Components/TabPanel';
import InfoTab from './Components/Info/InfoTab';
import SettlementsTab from './Components/Settlements/SettlementsTab';
import OffersTab from './Components/Offers/OffersTab';
import BillsTab from './Components/Bills/BillsTab';

const CustomerDetailsView = ({ customer, setNewDataCustomer, goToMain, consultants }) => {
  const { tab, box, tabs } = getStyles();

  const [tabIndex, setTabIndex] = useState(0);

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <Box style={{ marginTop: '152px' }}>
      <HeaderComponent screenNumber={0} leftButtonType="ArrowBackCustomHandler" customHandleBackButton={goToMain}/>
      <Paper square>
        <Box style={box}>
          <Tabs
            indicatorColor="primary"
            textColor="primary"
            value={tabIndex}
            onChange={handleChange}
            variant="fullWidth"
            style={tabs}
          >
            <Tab label="Информация о клиенте" style={{ ...commonStyles.text.robotoRegular16, ...tab }} wrapped />
            <Tab label="Расчеты" style={{ ...commonStyles.text.robotoRegular16, ...tab }} wrapped />
            <Tab label="Коммерческие предложения" style={{ ...commonStyles.text.robotoRegular16, ...tab }} wrapped />
            <Tab label="Счета, оплаты и отгрузки" style={{ ...commonStyles.text.robotoRegular16, ...tab }} wrapped />
          </Tabs>
        </Box>
      </Paper>
      <TabPanel id={0} value={tabIndex}>
        <InfoTab customer={customer} setNewDataCustomer={setNewDataCustomer} consultants={consultants}/>
      </TabPanel>
      <TabPanel id={1} value={tabIndex}>
        <SettlementsTab customerId={customer.id}/>
      </TabPanel>
      <TabPanel id={2} value={tabIndex}>
        <OffersTab customerId={customer.id}/>
      </TabPanel>
      <TabPanel id={3} value={tabIndex}>
        <BillsTab />
      </TabPanel>
    </Box>
  );
};

const getStyles = () => {
  return {
    tab: {
      letterSpacing: 'normal',
      textTransform: 'none',
    },
    box: {
      maxWidth: 1346,
      width: '100%',
      display: 'flex',
      margin: '0 auto',
    },
    tabs: {
      width: '100%',
    },
  };
};

export default CustomerDetailsView;
