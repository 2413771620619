export const inputs = [
  {
    title: 'ФИО сотрудника',
    name: 'fullName',
    typeInput: 'textInput',
    type: 'text',
    disabled: true,
    fields: [
      {
        name: 'firstName',
        title: 'Имя',
        type: 'text',
      },
      {
        name: 'middleName',
        title: 'Фамилия',
        type: 'text',
      },
      {
        name: 'lastName',
        title: 'Отчество',
        type: 'text',
      },
    ],
  },
  {
    title: 'Должность сотрудника',
    name: 'position',
    typeInput: 'textInput',
    type: 'text',
  },
  {
    title: 'Наименование организации',
    name: 'companyName',
    typeInput: 'textInput',
    type: 'text',
  },
  {
    title: 'Номер телефона',
    name: 'phone',
    typeInput: 'textInput',
    type: 'tel',
  },
  {
    title: 'ИНН',
    name: 'inn',
    typeInput: 'textInput',
    type: 'number',
  },
  {
    title: 'Электронная почта',
    name: 'email',
    typeInput: 'textInput',
    type: 'email',
  },
  {
    title: 'Юридический адрес',
    name: 'legalLocation',
    typeInput: 'textInput',
    type: 'text',
    disabled: true,
    fields: [
      {
        name: 'country',
        title: 'Страна',
        type: 'text',
      },
      {
        name: 'city',
        title: 'Город',
        type: 'text',
      },
      {
        name: 'street',
        title: 'Улица',
        type: 'text',
      },
      {
        name: 'apartment',
        title: 'Квартира',
        type: 'number',
      },
      {
        name: 'building',
        title: 'Дом',
        type: 'number',
      },
      {
        name: 'postalCode',
        title: 'Почтовый индекс',
        type: 'number',
      },
    ],
  },
  {
    title: 'Физический адрес',
    name: 'individualLocation',
    typeInput: 'textInput',
    type: 'text',
    disabled: true,
    fields: [
      {
        name: 'country',
        title: 'Страна',
        type: 'text',
      },
      {
        name: 'city',
        title: 'Город',
        type: 'text',
      },
      {
        name: 'street',
        title: 'Улица',
        type: 'text',
      },
      {
        name: 'apartment',
        title: 'Квартира',
        type: 'number',
      },
      {
        name: 'building',
        title: 'Дом',
        type: 'number',
      },
      {
        name: 'postalCode',
        title: 'Почтовый индекс',
        type: 'number',
      },
    ],
  },
  {
    title: 'Скидка',
    name: 'discount',
    typeInput: 'textInput',
    valueType: '%',
    type: 'number',
  },
  {
    title: 'Client ID',
    name: 'clientId',
    typeInput: 'textInput',
    type: 'number',
  },
  {
    title: 'Статус',
    name: 'accepted',
    typeInput: 'selectInput',
  },
  {
    title: 'Тип клиента',
    name: 'isCalculator',
    typeInput: 'selectInput',
  },
  {
    title: 'Инженер-консультант',
    name: 'engineer',
    typeInput: 'selectInput',
  },
];
