import React from 'react';
import commonStyles from '../../../../commonUtils/commonStyles';
import { Button, styled } from '@material-ui/core';
import PropTypes from 'prop-types';

const ButtonWrap = styled(({ fullWidth, width, color, ...other }) => <Button {...other} />)({
  width: (props) => (props.fullWidth ? '100%' : 'inherit'),
  minWidth: (props) => props.width || 106,
  fontWeight: 600,
  fontSize: '16px',
  padding: '10px 14px',
  textTransform: 'none',
  color: (props) =>
    props.variant === 'contained' && !props.disabled ? commonStyles.color.white : commonStyles.color.blue,
  backgroundColor: (props) => (props.variant === 'contained' && !props.disabled ? commonStyles.color.blue : undefined),
  borderColor: (props) => (props.variant === 'outlined' && !props.disabled ? commonStyles.color.blue : undefined),
  '&:hover': {
    backgroundColor: (props) => (props.variant === 'contained' && !props.disabled ? 'rgb(8, 69, 121)' : undefined),
  },
});

export default function ButtonComponent({ onClick, children, variant, disabled, ...restProps }) {
  return (
    <ButtonWrap variant={variant} onClick={onClick} {...restProps} disabled={disabled}>
      {children}
    </ButtonWrap>
  );
}

ButtonComponent.propTypes = {
  onClick: PropTypes.func.isRequired,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
};

ButtonComponent.defaultProps = {
  variant: 'contained',
  disabled: false,
};
