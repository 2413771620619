import React, { useEffect, useContext } from 'react';
import { useHistory, Link } from 'react-router-dom';

import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  IconButton,
  Typography,
  Toolbar,
  CardMedia,
  AppBar,
  Paper,
  Menu,
  MenuItem,
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import PeopleIcon from '@material-ui/icons/People';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { useTheme } from '@material-ui/core/styles';

import MainClass from '../../../main/MainClass';

import commonStyles from '../../../commonUtils/commonStyles';
import { ROUTES_LIST } from '../../../constants';
import { GlobalContext } from '../../..';

import LogoImg from '../../../assets/ETRA_ADMIN_logo2.png';

const drawerWidth = 340;
let theme;

function HeaderComponent(props) {
  theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const openUserMenu = Boolean(anchorEl);
  const { setAuthTokens } = useContext(GlobalContext);
  const history = useHistory();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const logOut = () => {
    setAuthTokens('');
  };

  const handleBackButton = () => {
    history.goBack();
  };

  const LeftHeaderButton = () => {
    switch (props.leftButtonType) {
      case 'ArrowBack':
        return (
          <IconButton color="inherit" onClick={handleBackButton} edge="start" style={getStyles().menuButton}>
            <ArrowBackIcon />
          </IconButton>
        );
      case 'ArrowBackCustomHandler':
        return (
          <IconButton
            color="inherit"
            onClick={props.customHandleBackButton}
            edge="start"
            style={getStyles().menuButton}
          >
            <ArrowBackIcon />
          </IconButton>
        );
      default:
        return (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            style={open === false ? getStyles().menuButton : getStyles().hide}
          >
            <MenuIcon />
          </IconButton>
        );
    }
  };

  const menuList = {
    menuItems: [
      {
        title: MainClass.getScreenNames()[0],
        icon: <PeopleIcon style={getStyles().listIcon} />,
        link: ROUTES_LIST.customers,
        role: 'user',
      },
      {
        title: MainClass.getScreenNames()[1],
        icon: <MenuBookIcon style={getStyles().listIcon} />,
        link: ROUTES_LIST.catalogs,
        role: 'user',
      },
      {
        title: MainClass.getScreenNames()[2],
        icon: <LibraryBooksIcon style={getStyles().listIcon} />,
        link: ROUTES_LIST.documents,
        role: 'user',
      },
    ],
  };

  const menuListContent = Object.keys(menuList).map((key, i) => {
    const isLast = Object.keys(menuList).length === i + 1;

    return (
      <List key={i} component="nav">
        {menuList[key].map(({ title, icon, link, role }, index) => {
          //if (role === userRole) {
          if (link) {
            return (
              <Link to={link} key={`${key}-${index}`} style={getStyles().listLink}>
                <ListItem button>
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText primary={title} style={commonStyles.text.robotoRegular18} />
                </ListItem>
              </Link>
            );
          }
          return (
            <ListItem button key={`${key}-${index}`}>
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText primary={title} style={commonStyles.text.robotoRegular18} />
            </ListItem>
          );
          //}
        })}
        {!isLast && <Divider />}
      </List>
    );
  });

  return (
    <AppBar
      position="fixed"
      style={{
        ...getStyles().topAppBar,
        ...{ height: '150px' },
      }}
    >
      <CardMedia component="img" style={getStyles().logoImage} image={LogoImg} />
      <AppBar elevation={5} position="static" style={open === false ? getStyles().appBar : getStyles().appBarShift}>
        <Toolbar style={getStyles().topToolbar}>
          <LeftHeaderButton />
          <Typography noWrap style={commonStyles.text.robotoRegular24}>
            {MainClass.getScreenNames()[props.screenNumber]}
          </Typography>
          <Box style={getStyles().rightSideButtonsContainer}>
            {/*shoppingCartElement()*/}
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={(event) => {
                //console.log(event.currentTarget);
                setAnchorEl(event.currentTarget);
              }}
              color="inherit"
              //style={{ marginRight: 'auto' }}
            >
              <AccountCircleIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={openUserMenu}
              onClose={() => {
                setAnchorEl(null);
              }}
              MenuListProps={{
                style: { width: '250px' },
              }}
            >
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                }}
              >
                <PersonOutlineIcon
                  style={{
                    color: commonStyles.color.blue,
                    fontSize: '25px',
                    marginRight: '15px',
                  }}
                />
                <Typography
                  style={commonStyles.text.robotoRegular16}
                  onClick={() => history.push(ROUTES_LIST.personalInfo)}
                >
                  Личные данные
                </Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  logOut();
                }}
              >
                <ExitToAppIcon
                  style={{
                    color: commonStyles.color.blue,
                    fontSize: '25px',
                    marginRight: '15px',
                  }}
                />
                <Typography style={commonStyles.text.robotoRegular16}>Выход из системы</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Paper square style={getStyles().headerMenuPaper}>
        {/*<TopTabBar />*/}
      </Paper>
      <Drawer variant="persistent" anchor="left" open={open} PaperProps={{ style: { ...getStyles().drawerPaper } }}>
        <div style={getStyles().drawerHeader}>
          <Divider />
          <IconButton onClick={handleDrawerClose}>
            {open === true ? (
              <ChevronLeftIcon
                style={{
                  color: commonStyles.color.blue,
                  fontSize: '35px',
                }}
              />
            ) : (
              <ChevronRightIcon style={{ color: commonStyles.color.blue, fontSize: '35px' }} />
            )}
          </IconButton>
        </div>
        <Divider />
        {menuListContent}
      </Drawer>
    </AppBar>
  );
}

const getStyles = () => {
  const styles = {
    topAppBar: {
      top: '0',
      bottom: 'auto',
      height: '198px',
      background: commonStyles.color.white,
    },
    logoImage: {
      marginTop: '20px',
      marginLeft: '20px',
      width: '518px',
      objectFit: 'initial',
    },
    topToolbar: {
      background: commonStyles.color.blue,
      height: '65px',
    },
    headerMenuPaper: {
      background: commonStyles.color.white,
      height: '48px',
    },
    appBar: {
      height: '65px',
      //background: commonStyles.color.blue,
      marginTop: '20px',
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      height: '65px',
      //background: commonStyles.color.blue,
      width: `calc(100% - ${drawerWidth}px)`,
      marginTop: '20px',
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: 'none',
    },
    drawerPaper: {
      width: `${drawerWidth}px`,
      // height: '430px',
      marginTop: '84px',
      border: '0px solid',
      borderTop: '1px solid',
      borderColor: 'rgba(0, 0, 0, 0.12)',
      boxShadow: '0px 5.5px 5px rgba(0,0,0,0.24), 0px 9px 18px rgba(0,0,0,0.18)',
    },
    drawerHeader: {
      display: 'flex',
      height: '65px',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      //...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
    listIcon: {
      color: commonStyles.color.blue,
      fontSize: '25px',
    },
    listLink: {
      textDecoration: 'none',
      color: 'inherit',
    },
    rightSideButtonsContainer: {
      display: 'flex',
      flexDirection: 'row',
      height: '65px',
      marginLeft: 'auto',
      alignItems: 'center',
      //border: '1px solid',
      //borderColor: 'red',
    },
  };
  return styles;
};

export default HeaderComponent;
