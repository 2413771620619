import React from 'react';
import { Typography, Checkbox } from '@material-ui/core';

import commonStyles from '../../../../commonUtils/commonStyles';
import { formatDate } from '../../../../commonUtils/commonUtils';
import Table from '../Table';

const SettlementList = ({ data, selectedIds, onChange }) => {
  const { text, textActive } = getStyles();

  const tableHeader = [
    {
      id: 'head_1',
      label: '',
      componentRender: ({ id }) => (
        <Checkbox checked={selectedIds.indexOf(id) !== -1} onChange={() => onChange(id)} style={{ color: commonStyles.color.blue }} />
      ),
    },
    {
      id: 'head_2',
      label: 'Название объекта',
      componentRender: ({ nameOfObject, id }) => (
        <Typography align="left" style={selectedIds.indexOf(id) !== -1 ? { ...textActive } : { ...text }}>
          {nameOfObject}
        </Typography>
      ),
    },
    {
      id: 'head_3',
      label: 'Номер расчета',
      componentRender: ({ number, id }) => (
        <Typography align="left" style={selectedIds.indexOf(id) !== -1 ? { ...textActive } : { ...text }}>
          {number}
        </Typography>
      ),
    },
    {
      id: 'head_4',
      label: 'Дата расчета',
      componentRender: ({ date, id }) =>
        (
        <Typography align="left" style={selectedIds.indexOf(id) !== -1 ? { ...textActive } : { ...text }}>
          {formatDate(date, '/')}
        </Typography>
      ),
    },
    {
      id: 'head_5',
      label: 'ПТО',
      componentRender: ({ tableData, id }) => (
        <Typography align="left" style={selectedIds.indexOf(id) !== -1 ? { ...textActive } : { ...text }}>
          {tableData.pto}
        </Typography>
      ),
    },
    {
      id: 'head_6',
      label: 'Назначение ПТО',
      componentRender: ({ destination, id }) => (
        <Typography align="left" style={selectedIds.indexOf(id) !== -1 ? { ...textActive } : { ...text }}>
          {destination}
        </Typography>
      ),
    },
    {
      id: 'head_7',
      label: 'Комментарии',
      componentRender: ({ commentaries, id }) => (
        <Typography align="left" style={selectedIds.indexOf(id) !== -1 ? { ...textActive } : { ...text }}>
          {commentaries}
        </Typography>
      ),
    },
  ];

  return <Table data={data} title="История расчетов" tableHeader={tableHeader} />;
};

const getStyles = () => {
  return {
    text: {
      ...commonStyles.text.robotoRegular18Light,
    },
    textActive: {
      color: commonStyles.color.blue,
      ...commonStyles.text.robotoRegular18,
    },
  };
};

export default SettlementList;
