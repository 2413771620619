import React, { useState, useContext } from 'react';
import { Box as MaterialBox, Grid } from '@material-ui/core';

import { validateEmail, validatePhone } from '../../../../commonUtils/commonUtils';
import { CLIENT_STATUS_LIST, CLIENT_TYPE_LIST, API_ENDPOINTS_LIST } from '../../../../constants'
import { GlobalContext } from '../../../..';
import useServerRequest from '../../../../hooks/serverRequest';

import Heading from '../Heading';
import Box from '../Box';
import InfoDetailInputs from './InfoDetailInputs';
import { inputs } from './InputList';
import Modal from './Modal';

const inputInitialState = {
  name: '',
};

const InfoTab = ({ customer, setNewDataCustomer, consultants }) => {
  const { box } = getStyles();
  const { setToastState, setGlobalLoading } = useContext(GlobalContext);
  const { getRequest } = useServerRequest(setGlobalLoading);

  const [input, setInput] = useState(inputInitialState);
  const [selectValues, setSelectValues] = useState({
    accepted: [CLIENT_STATUS_LIST.notAccepted, CLIENT_STATUS_LIST.accepted],
    isCalculator: [CLIENT_TYPE_LIST.client, CLIENT_TYPE_LIST.developer],
    engineer: consultants.map(consultant => consultant.consultantName),
  });
  const [buffer, setBuffer] = useState();
  const [error, setError] = useState('');
  const [modalOpen, setModalOpen] = useState(false);

  const onValid = (value, input) => {
    const isValid = [true];
    if (input?.typeInput === 'textInput') {
      if (value === '') {
        setError('Поле обязательно для заполнения');
        isValid.push(false);
      }
      if (input.type === 'tel' && !validatePhone(value)) {
        setError('Неверный формат телефона');
        isValid.push(false);
      }
      if (input.type === 'email' && !validateEmail(value)) {
        setError('Неверный формат электронной почты');
        isValid.push(false);
      }
      if (input.name === 'discount' && (Number(value) < 0 || Number(value) > 99)) {
        setError('Неверный формат скидки');
        isValid.push(false);
      }
      if (input.name === 'inn') {
        if (value.length !== 11) {
          setError('Необходимо 11 цифр ИНН');
          isValid.push(false);
        }
        if (value.includes('-')) {
          setError('Неверный формат ИНН');
          isValid.push(false);
        }
      }
      if (input.name === 'clientId' && value < 0) {
        setError('Неверный формат Client ID');
        isValid.push(false);
      }
    }
    return !isValid.includes(false);
  };

  const getDataForModal = () => {
    if (input.name === 'fullName') {
      return {
        firstName: customer.firstName,
        lastName: customer.lastName,
        middleName: customer.middleName,
      };
    } else {
      return customer[input.name];
    }
  };

  const closeModal = () => {
    setInput(inputInitialState);
    setModalOpen(!modalOpen);
  };

  const onCancel = (name) => {
    setInput(inputInitialState);
    setNewDataCustomer(buffer, name === 'engineer' ? 'consultantId' : name);
    setError('');
  };

  const onChange = (name) => {
    if (name === 'blocked') {
      setInput({ name });
    } else {
      const inputBuffer = inputs.find((input) => input.name === name);
      setInput(inputBuffer);
      if (inputBuffer.disabled) {
        setModalOpen(!modalOpen);
      }
    }
    setBuffer(customer[name]);
  };

  const changeUserData = (field, data) => {

    let userData = {}

    switch(field){
      case 'legalLocation':
      case 'individualLocation':
      case 'fullName':
        userData = {
          userData: data
        }
        break;
      case 'discount':
      case 'clientId':
      case 'isCalculator':
      case 'accepted':
        userData = {
          [field]: customer[field]
        }
        break;
      case 'engineer':
        userData = {
          'consultantId': customer['consultantId']
        }
        break;
      default:
        userData = {
          userData: {
            [field]: customer[field]
          }
        }
        break;
    }

    return new Promise((resolve, reject)=>{
      getRequest(`${API_ENDPOINTS_LIST.post_changeUserData}/${customer.id}`, JSON.stringify(userData)).then((res)=>{
        if(res.success === true){
          resolve()
        }
        if(res.code){
          setToastState((prevState) => ({
            ...prevState,
            toastOpen: true,
            toastMessage: res.message,
            toastStyle: 'error',
          }));
          reject()
        }
      })
    })
  };

  const onSave = (name, modal = false, data) => {
    if (onValid(customer[name], input) && !modal) {
      changeUserData(name, data).then(()=>{
        setError('');
        setInput(inputInitialState);
        setBuffer('');
      }).catch(()=>{
        setNewDataCustomer(buffer, name)
      })
    }
    if (modal) {
      changeUserData(name, data).then(()=>{
        setInput(inputInitialState);
        setModalOpen(!modalOpen);
        setNewDataCustomer(data, '', true);
      })

    }
  };

  return (
    <>
      <Box>
        <Heading text="Информация о пользователе" />
        <MaterialBox style={box}>
          <Grid container spacing={3}>
            <InfoDetailInputs
              userData={customer}
              onChangeInput={setNewDataCustomer}
              onCancel={onCancel}
              onSave={onSave}
              onChange={onChange}
              rowName={input.name}
              selectValues={selectValues}
              error={error}
              consultants={consultants}
            />
          </Grid>
        </MaterialBox>
      </Box>
      {modalOpen && (
        <Modal
          open={modalOpen}
          inputs={input?.fields || []}
          onSave={onSave}
          handleClose={closeModal}
          data={getDataForModal()}
          name={input.name}
        />
      )}
    </>
  );
};

const getStyles = () => {
  return {
    box: {
      marginTop: 30,
    },
    switchBox: {
      display: 'flex',
      alignItems: 'center',
    },
  };
};

export default InfoTab;
