import React from 'react';
import { Box as MaterialBox } from '@material-ui/core';

import commonStyles from '../../../commonUtils/commonStyles';

const Box = ({ children }) => {
  const { root } = getStyles();

  return <MaterialBox style={root}>{children}</MaterialBox>;
};

const getStyles = () => {
  return {
    root: {
      padding: '20px 20px 30px',
      borderRadius: 4,
      backgroundColor: commonStyles.color.white,
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25), 0px 1px 5px rgba(0, 0, 0, 0.2)',
    },
  };
};

export default Box;
