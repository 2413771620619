import React from 'react';
import { Typography, Radio } from '@material-ui/core';

import commonStyles from '../../../../commonUtils/commonStyles';

import Table from '../Table';

const BillList = ({ data, rowId, onChange }) => {
  const { text, textActive } = getStyles();

  const tableHeader = [
    {
      id: 'head_1',
      label: '',
      componentRender: ({ id }) => (
        <Radio checked={rowId === id} onChange={() => onChange(id)} style={{ color: commonStyles.color.blue }} />
      ),
    },
    {
      id: 'head_2',
      label: 'Дата счета',
      componentRender: ({ billAt, id }) => (
        <Typography align="left" style={rowId === id ? { ...textActive } : { ...text }}>
          {billAt}
        </Typography>
      ),
    },
    {
      id: 'head_3',
      label: 'Номер счета',
      componentRender: ({ number, id }) => (
        <Typography align="left" style={rowId === id ? { ...textActive } : { ...text }}>
          {number}
        </Typography>
      ),
    },
    {
      id: 'head_4',
      label: 'Название объекта',
      componentRender: ({ object, id }) => (
        <Typography align="left" style={rowId === id ? { ...textActive } : { ...text }}>
          {object}
        </Typography>
      ),
    },
    {
      id: 'head_5',
      label: 'Сумма счета, руб.',
      componentRender: ({ sum, id }) => (
        <Typography align="left" style={rowId === id ? { ...textActive } : { ...text }}>
          {sum}
        </Typography>
      ),
    },
    {
      id: 'head_6',
      label: 'Дата оплаты',
      componentRender: ({ paymentAt, id }) => (
        <Typography align="left" style={rowId === id ? { ...textActive } : { ...text }}>
          {paymentAt}
        </Typography>
      ),
    },
    {
      id: 'head_7',
      label: 'План готовности',
      componentRender: ({ planAt, id }) => (
        <Typography align="left" style={rowId === id ? { ...textActive } : { ...text }}>
          {planAt}
        </Typography>
      ),
    },
    {
      id: 'head_8',
      label: 'Факт готовности',
      componentRender: ({ factAt, id }) => (
        <Typography align="left" style={rowId === id ? { ...textActive } : { ...text }}>
          {factAt}
        </Typography>
      ),
    },
    {
      id: 'head_9',
      label: 'Дата отгрузки',
      componentRender: ({ shipmentAt, id }) => (
        <Typography align="left" style={rowId === id ? { ...textActive } : { ...text }}>
          {shipmentAt}
        </Typography>
      ),
    },
  ];

  return <Table title="История счетов, оплат и отгрузок" tableHeader={tableHeader} data={data} />;
};

const getStyles = () => {
  return {
    text: {
      ...commonStyles.text.robotoRegular18Light,
    },
    textActive: {
      color: commonStyles.color.blue,
      ...commonStyles.text.robotoRegular18,
    },
  };
};

export default BillList;
