import React, { useState } from 'react';

import FilterObject from '../FilterObject';
import ShowFileBox from '../ShowFileBox';
import BillList from './BillList';

const BillsTab = () => {
  const [filterValues, setFilterValues] = useState(['Не выбрано', 'Тест1', 'Тест2']);
  const [filterValue, setFilterValue] = useState(filterValues[0]);
  const [selectedId, setSelectedId] = useState();
  const [data, setData] = useState([
    {
      id: 0,
      billAt: '13.04.2020',
      number: '000090195',
      object: 'Тест1',
      sum: '80140,80 руб.',
      paymentAt: '13.04.2020',
      planAt: '13.04.2020',
      factAt: '13.04.2020',
      shipmentAt: 'Ожидайте',
    },
    {
      id: 1,
      billAt: '13.04.2020',
      number: '000090195',
      object: 'Тест2',
      sum: '80140,80 руб.',
      paymentAt: '13.04.2020',
      planAt: '13.04.2020',
      factAt: '13.04.2020',
      shipmentAt: 'Ожидайте',
    },
  ]);
  const [filteredData, setFilteredData] = useState(null);

  const onClickFilter = () => {
    let newData;
    if (filterValue === filterValues[0]) {
      newData = null;
    } else {
      newData = data.filter((value) => value.object === filterValue);
    }
    setFilteredData(newData);
  };

  const onClickShowBills = () => {
    alert('Счет');
  };

  return (
    <>
      <FilterObject values={filterValues} onChange={setFilterValue} onClick={onClickFilter} />
      <ShowFileBox buttonText="Показать Счет" onClick={onClickShowBills} />
      <BillList data={filteredData ?? data} rowId={selectedId} onChange={setSelectedId} />
    </>
  );
};

export default BillsTab;
