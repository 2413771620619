import React from 'react';
import PropTypes from 'prop-types';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Modal, Box } from '@material-ui/core';
import commonStyles from '../../../../commonUtils/commonStyles';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

function ModalContainer({ open, handleClose, noPadding, noBackground, maxWidth, children }) {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="modal"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box style={getStyles({ maxWidth }).body}>
          <Box style={getStyles({ maxWidth, noPadding, noBackground }).content}>
            {!noBackground && (
              <Box style={getStyles().close} onClick={handleClose}>
                <HighlightOffIcon style={getStyles().icon} fontSize="large" />
              </Box>
            )}

            {children}
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}

const getStyles = (props) => {
  const styles = {
    body: {
      position: 'relative',
      margin: '0 auto',
      width: '100%',
      maxWidth: props?.maxWidth || '719px',
      minHeight: 'calc(100vh - 80px)',
      maxHeight: ' calc(100vh - 80px)',
      boxShadow: '0px 4px 4px rgba(132, 132, 132, 0.03), 0px 2px 10px rgba(0, 0, 0, 0.06)',
      border: 'none',
      borderRadius: '4px',
      outline: 'none',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    content: {
      position: 'relative',
      width: props?.maxWidth ? 'auto' : '100%',
      marginTop: '75px',
      padding: props?.noPadding ? 0 : 75,
      height: 'auto',
      overflow: 'auto',
      background: props?.noBackground ? 'inherit' : commonStyles.color.white,
      zIndex: 2,
    },
    close: {
      position: 'absolute',
      right: 20,
      top: 20,
    },
    icon: {
      cursor: 'pointer',
      color: commonStyles.color.blue,
    },
  };
  return styles;
};

ModalContainer.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  noPadding: PropTypes.bool,
  noBackground: PropTypes.bool,
  maxWidth: PropTypes.string,
};

ModalContainer.defaultProps = {
  noPadding: false,
  noBackground: false,
  maxWidth: '',
};

export default ModalContainer;
