import React from 'react';
import { Grid, Typography, Paper } from '@material-ui/core';

import commonStyles from '../../../commonUtils/commonStyles';
import DropDown from '../../Components/Elements/DropDown/DropDownComponent';
import Button from '../../Components/Elements/Button/ButtonComponent';

const FilterObject = ({ values, selValue, onChange, onClick }) => {
  const { title, button, root } = getStyles();

  return (
    <Paper style={root}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <Typography style={{ ...title, ...commonStyles.text.robotoRegular18Light }}>Название объекта</Typography>
        </Grid>
        <Grid item xs={12} sm={9} md={7}>
          <DropDown variant="outlined" values={values} onChange={(e) => onChange(values[e.target.value])} />
        </Grid>
        <Grid item xs={12} sm={3} md={2}>
          <Button variant="contained" onClick={onClick} style={button}>
            Применить
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

const getStyles = () => {
  return {
    root: {
      padding: 20,
      marginBottom: 10,
    },
    title: {
      lineHeight: '46px',
    },
    button: {
      maxWidth: 220,
      width: '100%',
    },
  };
};

export default FilterObject;
