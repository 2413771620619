var singleton = new (function () {
  /*
  this.publicTestVar = {
    prop1: 'test',
  };
  var privateTestVar = null;

  this.init = () => {
    privateTestVar = 'test private';
  };

  this.getTestVar = () => {
    return privateTestVar;
  };

  this.setTestVar = (val) => {
    privateTestVar = val;
  };
  */
  /*
  this.getfetchBaseURL = () => {
    return 'https://api.etrann-online.com'; //'https://cors-anywhere.herokuapp.com/' + 'http://18.191.31.212:3000';
  };
  */

  var screenNames = [
    'Управление клиентами',
    'Каталоги и буклеты НПО ЭТРА',
    'Разрешительные документы',
    'Детальная информация о пользователе',
  ];

  this.getScreenNames = () => {
    return screenNames;
  };

  this.mainDataObj = {};

  //Init method
  this.init = () => {};
})();

export default singleton;
