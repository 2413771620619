import React, { useState, useContext } from 'react';

import AuthTextInputComponent from '../../../Components/Elements/AuthTextInput';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { styled } from '@material-ui/core/styles';

import { validateEmail } from '../../../../commonUtils/commonUtils';
import commonStyles from '../../../../commonUtils/commonStyles';
import { ERROR_TYPES_LIST, API_ENDPOINTS_LIST } from '../../../../constants';
import { GlobalContext } from '../../../..';
import useServerRequest from '../../../../hooks/serverRequest';
import ButtonComponent from '../../../Components/Elements/Button/ButtonComponent';

function ResetPasswordForm({ emailForReset, setLoginValues }) {
  const [emailValue, setEmailValue] = useState(emailForReset);
  const [errorsText, setErrorsText] = useState('');
  const { setGlobalLoading } = useContext(GlobalContext);
  const { getRequest: resetPassword } = useServerRequest(setGlobalLoading);

  const goBack = () => {
    setLoginValues((prevState) => ({
      ...prevState,
      step: 1,
    }));
  };

  const handleSubmit = () => {
    const isCorrect = validateEmail(emailValue);

    if (isCorrect) {
      resetPassword(API_ENDPOINTS_LIST.reset_password, JSON.stringify({ email: emailValue }), 'POST', false).then(
        (res) => {
          if (res && res.success !== false) {
            console.log(res);
            setLoginValues((prevState) => ({
              ...prevState,
              step: 3,
              emailForReset: emailValue,
            }));
          } else {
            setErrorsText(ERROR_TYPES_LIST.noSuchEmail);
          }
        }
      );
    } else {
      setErrorsText(ERROR_TYPES_LIST.invalidEmail);
    }
  };

  return (
    <>
      <StyledGrid container style={getStyles().root}>
        <Grid item xs={12} style={getStyles().inputWrapper}>
          <AuthTextInputComponent
            id="email"
            type="email"
            label="Электронная почта"
            variant="outlined"
            value={emailValue}
            onChange={(e) => setEmailValue(e.target.value)}
            error={!!errorsText}
            helperText={errorsText}
          />
        </Grid>
        <Typography style={getStyles().helperText}>
          Новый пароль будет автоматически сгенерирован и выслан на указанный адрес электронной почты.
        </Typography>
      </StyledGrid>
      <Box style={getStyles().btnBox}>
        <Grid container justify="space-between">
          <Grid item>
            <ButtonComponent variant="outlined" color="primary" onClick={goBack}>
              Отмена
            </ButtonComponent>
          </Grid>
          <Grid item>
            <ButtonComponent variant="contained" color="primary" onClick={handleSubmit}>
              Подтвердить
            </ButtonComponent>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

const StyledGrid = styled(Grid)({
  padding: '47px 80px 0',
  '& .MuiFormHelperText-root.Mui-error': {
    position: 'absolute',
    bottom: '-22px',
  },
});

const getStyles = () => {
  const styles = {
    inputWrapper: {
      marginBottom: '30px',
      position: 'relative',
    },
    helperText: {
      marginBottom: '100px',
      color: commonStyles.color.helperText,
    },
    errorMsg: {
      color: commonStyles.color.authError,
      position: 'absolute',
      bottom: '-20px',
      left: '0',
    },
    btnBox: {
      padding: '0 80px 30px',
    },
  };
  return styles;
};

export default ResetPasswordForm;
