import React from 'react';
import Layout from './Components/Layout';
import AuthForm from './Components/AuthForm';

function AuthScreen() {
  return (
    <Layout>
      <AuthForm />
    </Layout>
  );
}

export default AuthScreen;
