import React, { useState, useEffect } from 'react';
import { Box, Container } from '@material-ui/core';

import Filter from './Components/Filter';
import CustomerList from './Components/CustomerList';
import HeaderComponent from '../Components/HeaderComponent/HeaderComponent';

const initialStateFilter = {
  rrp: '0',
  consultantId: '0',
  companyName: '0',
  city: '0',
};

const MainView = ({ goToDetails, customers, additionalData }) => {
  const { container } = getStyles();

  const [filterValues, setFilterValues] = useState();
  const [consultants, setConsultants] = useState();

  useEffect(()=>{
    if(additionalData){
      const defaultValue = 'Не выбрано'
      const values = {}
      values.rrp = [defaultValue]
      values.consultantId = [defaultValue, ...additionalData.consultants.map(consultant => consultant.consultantName)]
      values.companyName = [defaultValue, ...additionalData.organizations]
      values.city = [defaultValue, ...additionalData.cities]
      setFilterValues(values)
      setConsultants(additionalData.consultants)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[additionalData])

  const [filterValue, setFilterValue] = useState(initialStateFilter);

  const [filteredCustomers, setFilteredCustomes] = useState(null);

  const onChange = (value, field) => {
    const newData = { ...filterValue, [field]: value };
    setFilterValue(newData);
  };

  const onReset = () => {
    setFilterValue(initialStateFilter);
    setFilteredCustomes(null);
  };

  const onClick = () => {
    // eslint-disable-next-line no-new-object
    const cloneFilter = new Object(filterValue);
    for (const filter in cloneFilter) {
      if (cloneFilter[filter] === '0') {
        delete cloneFilter[filter];
      }
    }
    const newData = customers.filter((value) => {
      const filterNameArray = Object.keys(cloneFilter);
      const array = [true];
      filterNameArray.forEach((filterName) => {
        if (value[filterName] === filterValues[filterName][Number(filterValue[filterName])]) {
          array.push(true);
        } else if (
          filterName === 'city' &&
          value.legalLocation[filterName] === filterValues[filterName][Number(filterValue[filterName])]
        ) {
          array.push(true);
        } else if (
          filterName === 'consultantId' &&
          value['consultantId'] === consultants[Number(filterValue['consultantId']) - 1].id){
          array.push(true);
        }else {
          array.push(false);
        }
      });

      return !array.includes(false);
    });
    setFilteredCustomes(newData);
  };

  return (
    <Box>
      <HeaderComponent screenNumber={0} />
      {customers && filterValues && (
        <Container style={container}>
          <Filter
            filterValues={filterValues}
            onChange={onChange}
            onReset={onReset}
            filterValue={filterValue}
            onClick={onClick}
          />
          <CustomerList data={filteredCustomers ?? customers} onClick={goToDetails} />
        </Container>
      )}
    </Box>
  );
};

const getStyles = () => {
  return {
    container: {
      marginBottom: '20px',
      marginTop: '178px',
      maxWidth: 1394,
    },
  };
};

export default MainView;
