import React from 'react';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

import commonStyles from '../../../../commonUtils/commonStyles';

export default function AuthTextInputComponent(props) {
  /* props:
      id
      style
      type
      label
      variant
      value
      onChange
      helperText
      error,
      disabled
  */

  const { id, style, type, label, variant, value, onChange, helperText, error, disabled, ...restProps } = props;

  return (
    <ValidationTextField
      id={id}
      style={style}
      type={type}
      label={label}
      variant={variant}
      value={value}
      onChange={onChange}
      helperText={helperText || null}
      fullWidth
      error={error}
      disabled={disabled}
      {...restProps}
    />
  );
}

const ValidationTextField = withStyles({
  root: {
    '& input:valid:focus + fieldset': {
      borderColor: commonStyles.color.blue,
    },
  },
})(TextField);
