import React, { useState } from 'react';
import { Table, TableBody, TableRow, TableCell, TablePagination } from '@material-ui/core';
import TableHead from './TableHead';
import PropTypes from 'prop-types';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function TableComponent({
  rows,
  head,
  headCells,
  displayAllRows,
  rowAction,
  buttonActions,
  canSelect,
  selectedRow,
  setSelectedRow,
  multipleSelect,
  ...restProps
}) {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dense] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const alreadySelected = (arr, id) => arr.find((item) => item.id === id);

  const handleClick = (e, id) => {
    if (canSelect) {
      if (multipleSelect) {
        setSelectedRow((prev) => {
          return alreadySelected(prev, id.id) ? [...prev.filter((item) => item.id !== id.id)] : [...prev, id];
        });
      } else {
        setSelectedRow(id);
      }
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const getTableRows = () => {
    const _rows = stableSort(rows, getComparator(order, orderBy));
    return displayAllRows ? _rows : _rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  };

  return (
    <>
      <Table aria-labelledby="tableTitle" size={dense ? 'small' : 'medium'} aria-label="enhanced table" {...restProps}>
        {head && (
          <TableHead
            order={order}
            headCells={headCells}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
        )}
        <TableBody>
          {getTableRows().map((row) => {
            const labelId = `enhanced-table-checkbox-${row.id}`;
            return (
              <React.Fragment key={`TableRow-${row.id}`}>
                <TableRow
                  hover
                  onClick={(event) => {
                    handleClick(event, row);
                  }}
                  role="checkbox"
                  tabIndex={-1}
                >
                  {headCells.map((item, i) => (
                    <TableCell
                      key={i}
                      component={item.long && 'th'}
                      id={labelId}
                      scope="row"
                      width={item.width}
                      align={item.align || 'right'}
                      padding={item.padding || 'default'}
                      style={
                        getStyles({
                          ...(item.width ? { width: item.width } : null),
                          ...(canSelect ? { cursor: 'pointer' } : null),
                        }).bodyPStyle
                      }
                    >
                      {item.id !== 'attention' &&
                        item.componentRender({
                          ...row,
                          buttonActions,
                          selectedRow,
                          index: i,
                        })}
                    </TableCell>
                  ))}
                </TableRow>
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage="Элементов на странице"
        style={getStyles().pagination}
      />
    </>
  );
}

const getStyles = (props) => {
  const styles = {
    pagination: {
      marginLeft: 'auto',
    },
    bodyPStyle: {
      fontSize: '18px',
      lineHeight: '21px',
      ...props,
    },
  };
  return styles;
};

TableComponent.propTypes = {
  rows: PropTypes.array.isRequired,
  headCells: PropTypes.array.isRequired,
  head: PropTypes.bool,
  displayAllRows: PropTypes.bool,
  buttonActions: PropTypes.object,
  canSelect: PropTypes.bool,
  selectedRow: PropTypes.array,
  setSelectedRow: PropTypes.func,
};

TableComponent.defaultProps = {
  head: true,
  displayAllRows: false,
  buttonActions: {},
  canSelect: false,
  selectedRow: [],
  setSelectedRow: () => false,
};

export default TableComponent;
