import React from 'react';
import { AppBar, Box, Toolbar } from '@material-ui/core';
import Button from '../../Components/Elements/Button/ButtonComponent';

export default function FooterConfirm({ stylesContext, save, cancel }) {
  const { controls } = stylesContext;
  const { footer, root } = getStyles();

  const buttonsLabel = {
    save: 'Сохранить',
    cancel: 'Отмена',
  };

  return (
    <div className={root} style={{ ...footer }}>
      <AppBar position="sticky" style={{ backgroundColor: 'white' }}>
        <Toolbar>
          <Box display="flex" justifyContent="flex-end" style={{ ...controls, width: '100%' }}>
            <Button
              style={{ width: 'initial', marginRight: '20px' }}
              width="118px"
              variant="outlined"
              onClick={() => cancel()}
            >
              {buttonsLabel.cancel}
            </Button>
            <Button onClick={() => save()} style={{ width: '220px' }}>
              {buttonsLabel.save}
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
    </div>
  );
}

const getStyles = () => {
  return {
    root: {
      flexGrow: 1,
    },
    footer: {
      position: 'fixed',
      bottom: 0,
      width: '100%',
    },
  };
};
