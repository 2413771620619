import React from 'react';
import { Paper } from '@material-ui/core';

import Button from '../../Components/Elements/Button/ButtonComponent';

const ShowFileBox = ({ buttonText, onClick }) => {
  const { root } = getStyles();

  return (
    <Paper style={root}>
      <Button variant="contained" onClick={onClick}>
        {buttonText}
      </Button>
    </Paper>
  );
};

const getStyles = () => {
  return {
    root: {
      padding: '9px 20px 10px',
      display: 'flex',
      justifyContent: 'flex-end',
    },
  };
};

export default ShowFileBox;
