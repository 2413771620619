import React, { useState, useEffect, useContext } from 'react';

import OfferList from './OfferList';
import ShowFileBox from '../ShowFileBox';
import useServerRequest from '../../../../hooks/serverRequest';
import { GlobalContext } from '../../../..'
import { API_ENDPOINTS_LIST, BASE_URL } from '../../../../constants'

const OffersTab = ({customerId}) => {
  const [selectedId, setSelectedId] = useState(null);
  const { setGlobalLoading, setToastState } = useContext(GlobalContext);
  const { getRequest } = useServerRequest(setGlobalLoading)
  const { getRequest: pdfRequest } = useServerRequest(setGlobalLoading, true)
  const [data, setData] = useState();

  const getToast = (message) => {
    setToastState((prevState) => ({
      ...prevState,
      toastOpen: true,
      toastMessage: message,
      toastStyle: 'error',
    }));
  }

  useEffect(()=>{
    getRequest(`${API_ENDPOINTS_LIST.get_offers}/${customerId}`, '', 'GET').then((res)=>{
      if(res && !res.code){
        setData(res.result)
      }
      else{
        getToast(res.message)
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const onClickDownloadPDF = () => {
    if(selectedId !== null){
      pdfRequest(API_ENDPOINTS_LIST.post_token_offer, JSON.stringify({offerId: String(selectedId)}), 'POST').then((res)=>{
        if(res && !res.code){
          const pdfToken = res.result.token;
          const downloadlink = pdfToken && `${BASE_URL}${API_ENDPOINTS_LIST.get_download_offer}/${pdfToken}/`;
          window.location.href = downloadlink;
        }
        else{
          getToast(res.message)
        }
      })
    }
    else{
      getToast('Выберите необходимое коммерческое предложение')
    }
  };

  return (
    <>
      <ShowFileBox buttonText="Скачать PDF документ" onClick={onClickDownloadPDF} />
      {data && <OfferList data={data} rowId={selectedId} onChange={setSelectedId} />}
    </>
  );
};

export default OffersTab;
