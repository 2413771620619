import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { GlobalContext } from '../../..';

export default function PrivateRoute({ children, ...rest }) {
  const { authTokens } = useContext(GlobalContext);

  return <Route {...rest} render={() => (authTokens ? children : <Redirect to="/" />)} />;
}
