import React from 'react';
import { Grid, Typography } from '@material-ui/core';

import commonStyles from '../../../../commonUtils/commonStyles';
import TextInput from '../../../Components/Elements/TextInput/TextInputComponent';

const ModalInputs = ({ input, value, onChange, index, error }) => {
  const { title } = getStyles();

  return (
    <>
      <Grid item xs={4}>
        <Typography style={{ ...title, ...commonStyles.text.robotoRegular18Light }}>{input.title}</Typography>
      </Grid>
      <Grid item xs={8}>
        <TextInput
          value={value}
          onChange={(e) => onChange(e.target.value, input.name)}
          tabIndex={index}
          id={input.name}
          value_type={input.valueType ? input.valueType : 'only_value'}
          input_type={input.type}
          inputProps={input.type === 'number' ? { min: '0' } : {}}
          fieldIncorrect={Boolean(error)}
          helperText={error ? error : ''}
        />
      </Grid>
    </>
  );
};

const getStyles = () => {
  return {
    title: {
      lineHeight: '46px',
    },
  };
};

export default ModalInputs;
