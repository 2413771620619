// https://api.etrann-online.com
require('dotenv').config();
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const BACK_OFFICE_URL = process.env.REACT_APP_BACK_OFFICE_URL;

export const TOAST_STATE = {
  toastOpen: false,
  toastAutohideDuration: 15000,
  toastStyle: 'success',
  toastMessage: '',
};

export const ROUTES_LIST = {
  customers: '/customers',
  documents: '/documents',
  catalogs: '/catalogs',
};

export const API_ENDPOINTS_LIST = {
  //AUTH
  login: '/auth/login',
  userInfo: '/users/info',
  reset_password: '/auth/resetPassword',
  //DOCS
  get_docs: '/docs',
  //CUSTOMER CONTROL
  get_customer: '/customer',
  get_additionalData: '/customer/additionalData',
  post_changeUserData: '/customer/changeUserData',
  get_stores: '/customer/stores',
  get_offers: '/customer/offers',
  //PDF
  post_token_store: '/pdf/getToken/store',
  post_token_offer: '/pdf/getToken/offer',
  get_view_store: '/pdf/view/store',
  get_download_offer: '/pdf/download/offer'
};

export const USER_TYPES_LIST = {
  rrp: 'RRP',
};

export const ERROR_TYPES_LIST = {
  invalidEmail: 'Неверный адрес электронной почты',
  invalidPassword: 'Введен не правильный пароль',
  noSuchEmail: 'Адрес электронной почты не зарегистрирован',
};

export const CLIENT_STATUS_LIST = {
  accepted: 'Подтверждено',
  notAccepted: 'Не подтверждено'
}

export const CLIENT_TYPE_LIST = {
  developer: 'Разработчик',
  client: 'Клиент'
}
