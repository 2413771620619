import React from 'react';
import { Box as MaterialBox, Typography, Grid, Paper } from '@material-ui/core';

import ButtonComponent from '../../Components/Elements/Button/ButtonComponent';
import DropdDown from '../../Components/Elements/DropDown/DropDownComponent';
import commonStyles from '../../../commonUtils/commonStyles';

const Filter = ({ filterValues, onChange, onReset, filterValue, onClick }) => {
  const { root, button, buttonOk, text } = getStyles();

  return (
    <Paper style={root}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4} lg={2}>
          <Typography noWrap style={{ ...commonStyles.text.robotoRegular18Light, ...text }}>
            РРП
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8} lg={4}>
          <DropdDown
            variant="outlined"
            values={filterValues.rrp}
            onChange={(e) => onChange(e.target.value, 'rrp')}
            selValue={filterValue.rrp}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={2}>
          <Typography noWrap style={{ ...commonStyles.text.robotoRegular18Light, ...text }}>
            Инженер консультант
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8} lg={4}>
          <DropdDown
            variant="outlined"
            values={filterValues.consultantId}
            onChange={(e) => onChange(e.target.value, 'consultantId')}
            selValue={filterValue.consultantId}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={2}>
          <Typography noWrap style={{ ...commonStyles.text.robotoRegular18Light, ...text }}>
            Организация
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8} lg={6}>
          <DropdDown
            variant="outlined"
            values={filterValues.companyName}
            onChange={(e) => onChange(e.target.value, 'companyName')}
            selValue={filterValue.companyName}
          />
        </Grid>
        <Grid item xs={false} lg={4} style={{ padding: 0 }}></Grid>
        <Grid item xs={12} sm={4} lg={2}>
          <Typography noWrap style={{ ...commonStyles.text.robotoRegular18Light, ...text }}>
            Город
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8} lg={6}>
          <DropdDown
            variant="outlined"
            values={filterValues.city}
            onChange={(e) => onChange(e.target.value, 'city')}
            selValue={filterValue.city}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <MaterialBox display="flex" justifyContent="flex-end" alignItems="flex-end">
            <ButtonComponent variant="outlined" style={button} onClick={onReset}>
              Сброс
            </ButtonComponent>
            <ButtonComponent variant="contained" style={{ ...button, ...buttonOk }} onClick={onClick}>
              Применить
            </ButtonComponent>
          </MaterialBox>
        </Grid>
      </Grid>
    </Paper>
  );
};

const getStyles = () => {
  return {
    root: {
      padding: '20px 20px 30px',
      marginBottom: 20,
    },
    button: {
      height: 46,
    },
    buttonOk: {
      width: 220,
      marginLeft: 20,
    },
    text: {
      lineHeight: '43px',
    },
  };
};

export default Filter;
