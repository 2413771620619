import React, { useState } from 'react';
import { Card, Grid, Typography, Box, Divider } from '@material-ui/core';
import commonStyles from '../../../commonUtils/commonStyles';
import TextInputComponent from '../../Components/Elements/TextInput/TextInputComponent';
import ButtonComponent from '../../Components/Elements/Button/ButtonComponent';

const addNewDocumentLabel = 'Добавление нового документа';
const addNewDocumentFormLabel = {
  name: 'Название раздела',
  documentId: 'Порядковый номер',
  loadDocument: 'Загрузить документ',
  reset: 'Сброс',
  add: 'Добавить',
};

const fileRef = React.createRef();

const AddNewDocument = ({ data, setValueHandler, stylesContext, resetForm, isUploadDisabled, uploadPDF }) => {
  const { card, emptyImage } = getStyles();
  const { formContainer, titleText, breakLineTop, breakLineBottom, controls } = stylesContext;

  const handleFileUpload = () => {
    fileRef.current.click();
  };

  const handlePreviewUpload = async (event) => {
    if (fileRef.current.files.length) {
      // start preloader
      const input = document.querySelector('input[type="file"]');
      const [file] = input.files;
      const fileData = new FormData();
      fileData.append('file', file);
      uploadPDF('preview', fileData, fileRef.current.files[0].name);
    }
  };

  return (
    <Card style={{ ...card }}>
      <Grid container style={{ ...formContainer }} spacing={3}>
        <Grid item xs={12}>
          <Typography
            style={{
              ...commonStyles.text.robotoRegular24,
              ...titleText,
            }}
          >
            {addNewDocumentLabel}
          </Typography>
        </Grid>

        <Grid item xs={12} md={3}>
          <Typography
            style={{
              ...commonStyles.text.robotoRegular16,
            }}
          >
            {addNewDocumentFormLabel.name}
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextInputComponent
            value={data.section}
            value_type="only_value"
            onChange={(e) => setValueHandler(e.target.value, 'section')}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Box display="flex" justifyContent="flex-end">
            <div style={{ position: 'relative' }}>
              <ButtonComponent
                variant="outlined"
                width="240px"
                disableFocusRipple={true}
                onClick={(e) => handleFileUpload(e)}
              >
                {addNewDocumentFormLabel.loadDocument}
              </ButtonComponent>
              <input
                type="file"
                hidden
                ref={fileRef}
                onChange={(event) => handlePreviewUpload(event)}
                accept="application/pdf"
              />
            </div>
          </Box>
        </Grid>

        <Grid item xs={12} md={3}>
          <Typography
            style={{
              ...commonStyles.text.robotoRegular16,
            }}
          >
            {addNewDocumentFormLabel.documentId}
          </Typography>
        </Grid>
        <Grid item xs={12} md={2}>
          <TextInputComponent
            value_type="only_value"
            value={data.documentId}
            onChange={(e) => setValueHandler(e.target.value, 'documentId')}
            input_type="number"
          />
        </Grid>
        <Grid item xs={12} md={7} />
      </Grid>

      <Divider style={{ ...breakLineTop }} />
      <Grid container style={{ ...formContainer }} spacing={3}>
        <Grid item xs={12} md={1}>
          {data.previewImage !== null ? (
            <img style={{ maxWidth: '60px' }} src={data.previewImage} alt="" />
          ) : (
            <div style={{ ...emptyImage }}>
              <Typography
                style={{
                  ...commonStyles.text.robotoRegular64,
                }}
              >
                ?
              </Typography>
            </div>
          )}
        </Grid>

        <Grid item xs={12} md={2}>
          <Typography
            style={{
              ...commonStyles.text.robotoRegular16,
            }}
            noWrap={true}
          >
            {data.section}
          </Typography>
        </Grid>

        <Grid item xs={12} md={9}>
          <Typography
            style={{
              ...commonStyles.text.robotoRegular16,
            }}
          >
            {data.fileName}
          </Typography>
        </Grid>
      </Grid>
      <Divider style={{ ...breakLineBottom }} />

      <Box display="flex" justifyContent="flex-end" style={{ ...controls }}>
        <ButtonComponent
          variant="outlined"
          style={{ marginRight: '20px' }}
          width="118px"
          disabled={isUploadDisabled}
          onClick={() => resetForm()}
        >
          {addNewDocumentFormLabel.reset}
        </ButtonComponent>
        <ButtonComponent
          onClick={() => {
            uploadPDF('upload', null, null);
          }}
          disabled={isUploadDisabled}
          width="220px"
        >
          {addNewDocumentFormLabel.add}
        </ButtonComponent>
      </Box>
    </Card>
  );
};

const getStyles = () => {
  return {
    card: {
      marginTop: '20px',
    },
    emptyImage: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      lineHeight: '135px',
      backgroundColor: commonStyles.color.blue,
      color: commonStyles.color.white,
      width: '60px',
      height: '85px',
    },
  };
};

export default AddNewDocument;
