import React, { useState } from 'react';
import { Grid } from '@material-ui/core';

import ModalContainer from '../../../Components/Elements/Modal/ModalContainer';
import ModalInputs from './ModalInputs';
import Buttons from './ButtonsInfoTab';

const Modal = ({ inputs, onSave, open, handleClose, data, name }) => {
  const [values, setValues] = useState(data);
  const [errors, setErrors] = useState({});

  const onChange = (value, field) => {
    const newData = { ...values, [field]: value };
    setValues(newData);
  };

  const isValid = () => {
    const arr = [true];
    const text = 'Поле обязательно для заполнения';
    const errorsBuffer = {};
    for (const key in values) {
      if (values[key] === '') {
        errorsBuffer[key] = text;
        arr.push(false);
      }
    }
    setErrors(errorsBuffer);
    return !arr.includes(false);
  };

  const onClick = (name) => {
    if (isValid()) {
      const newData = name === 'fullName' ? values : { [name]: values };
      onSave(name, true, newData);
    }
  };

  const onCancel = () => {
    setValues(data);
  };

  return (
    <ModalContainer open={open} handleClose={handleClose}>
      <Grid container spacing={3}>
        {inputs.map((input, index) => (
          <ModalInputs
            key={index}
            input={input}
            value={values[input.name]}
            onChange={onChange}
            index={index}
            error={errors[input.name] || ''}
          />
        ))}
        <Grid item xs={12}>
          <Buttons value={name} onCancel={onCancel} onSave={onClick} name={name} />
        </Grid>
      </Grid>
    </ModalContainer>
  );
};

export default Modal;
