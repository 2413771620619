import React, { useState, useEffect, useContext } from 'react';
import { Container, Box } from '@material-ui/core';
import { ThemeProvider, createMuiTheme } from '@material-ui/core';

import commonStyles from '../../commonUtils/commonStyles';
import { compareObjects } from '../../commonUtils/commonUtils';

import { API_ENDPOINTS_LIST } from '../../constants';
import { GlobalContext } from '../..';
import useServerRequest from '../../hooks/serverRequest';
import useUploadRequest from '../../hooks/uploadRequest';

import HeaderComponent from '../Components/HeaderComponent/HeaderComponent';
import FooterConfirm from './Components/FooterConfirm';
import CatalogTable from './Components/CatalogTable';
import AddNewDocument from './Components/AddNewDocument';

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: commonStyles.color.authError,
    },
    primary: {
      main: commonStyles.color.blue,
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

const CatalogsScreen = () => {
  const { setToastState, setGlobalLoading } = useContext(GlobalContext);
  const { getRequest: getCatalog } = useServerRequest(setGlobalLoading);
  const { getRequest: sendCatalog } = useServerRequest(setGlobalLoading);
  const { uploadRequest: uploadForPreview } = useUploadRequest(setGlobalLoading);
  const { uploadRequest: uploadFile } = useUploadRequest(setGlobalLoading);

  const { container, titleText, formContainer, breakLineTop, breakLineBottom, controls } = getStyles();
  const addDocumentInitialState = {
    section: '',
    documentId: '',
    fileName: 'Название документа',
    previewImage: null,
    fileDataObj: null,
  };
  const [documentData, setDocumentData] = useState(addDocumentInitialState);
  const [isUploadDisabled, setUploadDisabled] = useState(true);
  const [isInitialCatalog, setIsInitialCatalog] = useState(true);
  //const [initialCatalog, setInitialCatalog] = useState([]);
  const [catalogList, setCatalogList] = useState([]);

  useEffect(() => {
    getDocumentsList();
  }, []);

  useEffect(() => {
    if (compareObjects(documentData, addDocumentInitialState)) {
      setUploadDisabled(true);
    } else {
      setUploadDisabled(false);
    }
  }, [documentData]);

  const getDocumentsList = () => {
    getCatalog(API_ENDPOINTS_LIST.get_docs + '/Brochures', '', 'GET').then((res) => {
      if (res && res.status === 200) {
        //setInitialCatalog(res.result);
        setCatalogList(res.result);
      } else {
        setToastState((prevState) => ({
          ...prevState,
          toastOpen: true,
          toastMessage: 'Ошибка запроса к серверу: ' + res.code + ' ' + res.message,
          toastStyle: 'error',
        }));
      }
    });
  };

  const uploadPDF = async (uploadType, fileData, fileName) => {
    if (uploadType === 'preview') {
      uploadForPreview(API_ENDPOINTS_LIST.get_docs + '/preview', fileData, true).then((res) => {
        if (res && res.status === 200) {
          setDocumentData((prevState) => ({
            ...prevState,
            fileName: fileName,
            previewImage: `data:image/jpg;base64,${res.data}`,
            fileDataObj: fileData,
          }));
        } else {
          setToastState((prevState) => ({
            ...prevState,
            toastOpen: true,
            toastMessage:
              'Ошибка запроса к серверу: ' + JSON.parse(res.data).error.code + ' ' + JSON.parse(res.data).error.message,
            toastStyle: 'error',
          }));
        }
      });
    }
    if (uploadType === 'upload') {
      let fileData = documentData.fileDataObj;
      fileData.append('number', documentData.documentId);
      fileData.append('sectionName', documentData.section);

      uploadFile(API_ENDPOINTS_LIST.get_docs + '/send/Brochures', fileData, false).then((res) => {
        if (res && res.status === 200) {
          setToastState((prevState) => ({
            ...prevState,
            toastOpen: true,
            toastMessage: 'Файл загружен!',
            toastStyle: 'success',
          }));
          setDocumentData(addDocumentInitialState);
          setUploadDisabled(true);
          getDocumentsList();
        } else {
          setToastState((prevState) => ({
            ...prevState,
            toastOpen: true,
            toastMessage:
              'Ошибка запроса к серверу: ' + JSON.parse(res.data).error.code + ' ' + JSON.parse(res.data).error.message,
            toastStyle: 'error',
          }));
        }
      });
    }
  };

  const setValueHandler = (value, field) => {
    const newData = { ...documentData, [field]: value };
    setDocumentData(newData);
  };

  const resetForm = () => {
    setDocumentData(addDocumentInitialState);
    setUploadDisabled(true);
  };

  const deletePDF = (id) => {
    const index = catalogList.findIndex((item) => item.id === id);
    catalogList.splice(index, 1);
    setCatalogList(() => [...catalogList]);
    setIsInitialCatalog(() => false);
  };

  const shiftUp = (props) => {
    const index = catalogList.findIndex((item) => item.id === props.id);
    if (index) {
      [catalogList[index], catalogList[index - 1]] = [catalogList[index - 1], catalogList[index]];
      setCatalogList(() => [...catalogList]);
      setIsInitialCatalog(() => false);
    }
  };

  const shiftDown = (props) => {
    const index = catalogList.findIndex((item) => item.id === props.id);
    if (index !== catalogList.length - 1) {
      [catalogList[index], catalogList[index + 1]] = [catalogList[index + 1], catalogList[index]];
      setCatalogList(() => [...catalogList]);
      setIsInitialCatalog(() => false);
    }
  };

  const save = () => {
    const dataForServer = catalogList.map((obj) => {
      return obj.documentId;
    });
    sendCatalog(API_ENDPOINTS_LIST.get_docs + '/order/Brochures', JSON.stringify(dataForServer)).then((res) => {
      if (res && res.status === 200) {
        setDocumentData(addDocumentInitialState);
        setUploadDisabled(true);
        getDocumentsList();
        setIsInitialCatalog(() => true);
      } else {
        setToastState((prevState) => ({
          ...prevState,
          toastOpen: true,
          toastMessage: 'Ошибка запроса к серверу: ' + res.code + ' ' + res.message,
          toastStyle: 'error',
        }));
      }
    });
  };

  const cancel = () => {
    setIsInitialCatalog(() => true);
    getDocumentsList();
  };

  return (
    <ThemeProvider theme={theme}>
      <Box className="root-container">
        <HeaderComponent screenNumber={1} />
        <Container style={container}>
          <AddNewDocument
            data={documentData}
            setValueHandler={setValueHandler}
            stylesContext={{
              titleText,
              formContainer,
              breakLineTop,
              breakLineBottom,
              controls,
            }}
            resetForm={resetForm}
            isUploadDisabled={isUploadDisabled}
            uploadPDF={(uploadType, uploadData, fileName) => {
              uploadPDF(uploadType, uploadData, fileName);
            }}
          />
          <CatalogTable
            data={catalogList}
            deletePDF={deletePDF}
            shiftUp={shiftUp}
            shiftDown={shiftDown}
            stylesContext={{ titleText }}
          />
        </Container>
        {!isInitialCatalog ? <FooterConfirm stylesContext={{ controls }} save={save} cancel={cancel} /> : null}
      </Box>
    </ThemeProvider>
  );
};

const getStyles = () => {
  return {
    container: {
      //border: '1px solid',
      //borderColor: 'green',
      marginTop: '170px',
    },
    titleText: {
      paddingTop: '20px',
      paddingBottom: '20px',
      textAlign: 'center',
      textTransform: 'none',
      color: commonStyles.color.blue,
    },
    formContainer: {
      padding: '0 20px',
    },
    breakLineTop: {
      borderColor: '#E0E0E0',
      marginTop: '20px',
      marginBottom: '10px',
    },
    breakLineBottom: {
      borderColor: '#E0E0E0',
      marginTop: '10px',
    },
    controls: {
      padding: '20px',
    },
  };
};

export default CatalogsScreen;
