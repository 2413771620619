import React from 'react';
import { Typography, Radio } from '@material-ui/core';

import commonStyles from '../../../../commonUtils/commonStyles';
import { formatDate } from '../../../../commonUtils/commonUtils';
import Table from '../Table';

const OfferList = ({ rowId, onChange, data }) => {
  const { text, textActive } = getStyles();

  const tableHeader = [
    {
      id: 'head_1',
      label: '',
      componentRender: ({ id }) => (
        <Radio checked={rowId === id} onChange={() => onChange(id)} style={{ color: commonStyles.color.blue }} />
      ),
    },
    {
      id: 'head_2',
      label: 'Название объекта',
      componentRender: ({ nameOfObject, id }) => (
        <Typography align="left" style={rowId === id ? { ...textActive } : { ...text }}>
          {nameOfObject}
        </Typography>
      ),
    },
    {
      id: 'head_3',
      label: 'Дата КП',
      componentRender: ({ date, id }) => (
        <Typography align="left" style={rowId === id ? { ...textActive } : { ...text }}>
          {formatDate(date, '.')}
        </Typography>
      ),
    },
    {
      id: 'head_4',
      label: 'Номер КП',
      componentRender: ({ number, id }) => (
        <Typography align="left" style={rowId === id ? { ...textActive } : { ...text }}>
          {number}
        </Typography>
      ),
    },
    {
      id: 'head_5',
      label: 'Сумма КП, руб.',
      componentRender: ({ totalPrice, id }) => (
        <Typography align="left" style={rowId === id ? { ...textActive } : { ...text }}>
          {totalPrice}
        </Typography>
      ),
    },
    {
      id: 'head_6',
      label: 'Скидка%',
      componentRender: ({ discount, id }) => (
        <Typography align="left" style={rowId === id ? { ...textActive } : { ...text }}>
          {discount}
        </Typography>
      ),
    },
    {
      id: 'head_7',
      label: 'Годен до',
      componentRender: ({ expiryDate, id }) => (
        <Typography align="left" style={rowId === id ? { ...textActive } : { ...text }}>
          {formatDate(expiryDate, '.')}
        </Typography>
      ),
    },
  ];

  return <Table data={data} title="История коммерческих предложений" tableHeader={tableHeader} />;
};

const getStyles = () => {
  return {
    text: {
      ...commonStyles.text.robotoRegular18Light,
    },
    textActive: {
      color: commonStyles.color.blue,
      ...commonStyles.text.robotoRegular18,
    },
  };
};

export default OfferList;
