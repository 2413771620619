import React from 'react';
import { Typography } from '@material-ui/core';

import commonStyles from '../../../commonUtils/commonStyles';

const Heading = ({ text }) => {
  const { h5 } = getStyles();

  return (
    <Typography variant="h5" style={{ ...h5, ...commonStyles.text.robotoRegular24 }}>
      {text}
    </Typography>
  );
};

const getStyles = () => {
  return {
    h5: {
      textAlign: 'center',
      color: commonStyles.color.blue,
      marginBottom: 5,
    },
  };
};

export default Heading;
