import React from 'react';
import { TableContainer, Paper } from '@material-ui/core';

import TableComponent from '../../Components/Elements/Table/TableComponent';
import commonStyles from '../../../commonUtils/commonStyles';

import Heading from './Heading';

const Table = ({ tableHeader, data, title, selectedRow, setSelectedRow }) => {
  const { root } = getStyles();

  return (
    <TableContainer style={root} component={Paper} className="table-container">
      <Heading text={title} />
      <TableComponent headCells={tableHeader} rows={data} selectedRow={selectedRow} setSelectedRow={setSelectedRow} />
    </TableContainer>
  );
};

const getStyles = () => {
  return {
    root: {
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25), 0px 1px 5px rgba(0, 0, 0, 0.2)',
      borderRadius: 4,
      backgroundColor: commonStyles.color.white,
      marginTop: 10,
      padding: '20px 0 30px',
    },
  };
};

export default Table;
