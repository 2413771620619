const commonStyles = {
  color: {
    background: '#EBEBF0',
    white: '#FFFFFF',
    black: '#000000',
    blue: '#0C63AD',
    tabInactive: '#787878',
    hotWater: '#FF0011',
    coldWater: '#008AFF',
    authError: '#E4333F',
    helperText: '#787878',
    greyLight: '#E0E0E0',
  },
  text: {
    robotoRegular64: {
      fontFamily: '"Roboto", sans-serif',
      fontStyle: 'normal',
      fontSize: '64px',
      fontWeight: 400,
    },
    robotoRegular24: {
      fontFamily: '"Roboto", sans-serif',
      fontStyle: 'normal',
      fontSize: '23px',
      fontWeight: 550,
    },
    robotoRegular18: {
      fontSize: '17px',
      fontFamily: '"Roboto", sans-serif',
      fontStyle: 'normal',
      fontWeight: 550,
    },
    robotoRegular18Light: {
      fontSize: '17px',
      fontFamily: '"Roboto", sans-serif',
      fontStyle: 'normal',
      //fontWeight: 550,
    },
    robotoRegular16: {
      fontSize: '15px',
      fontFamily: '"Roboto", sans-serif',
      fontStyle: 'normal',
      fontWeight: 550,
    },
  },
};

export default commonStyles;
