import { useCallback } from 'react';
import { BASE_URL, BACK_OFFICE_URL } from '../constants';

const useUploadRequest = (setGlobalLoading, isBaseUrl) => {
  const token = JSON.parse(localStorage.getItem('tokens'))?.token;
  let baseUrl = isBaseUrl ? BASE_URL : BACK_OFFICE_URL;
  /**
   * Main function to make server request
   *
   * requestURL - String - required
   * requestObj - Object - required. You need to put here Object for upload
   * returnData - String. Do it need to return some data back? Default - no
   */
  const uploadRequest = useCallback(
    (requestURL, requestObj, returnData) => {
      setGlobalLoading(true);
      return new Promise((resolve) => {
        serverRequest(token, baseUrl + requestURL, requestObj, returnData)
          .then((obj) => {
            setGlobalLoading(false);
            resolve(obj);
          })
          .catch((e) => {
            console.log(e);
          });
      });
    },
    [setGlobalLoading, token, baseUrl]
  );

  return { uploadRequest };
};

const serverRequest = async (token, requestURL, requestObj, returnData = false) => {
  let response;
  try {
    response = await fetch(requestURL, {
      method: 'POST',
      body: requestObj,
      headers: {
        Authorization: token,
      },
    });
    const res = await response;
    //const status = res.status;
    //const data = await response;
    let respText = null;
    if (returnData) {
      respText = await response.text();
      //console.log('uplData: ' + respText);
      return { status: res.status, data: respText };
    } else {
      return { status: res.status, data: null };
    }
    /*
    if (data.error !== undefined) {
      return { data: data.error, status, respText };
    } else {
      return { data: data, status, respText };
    }
    */
  } catch (error) {
    return { res: 'error', data: { code: 'Сервер не доступен', message: '' } };
  }
};

export default useUploadRequest;
