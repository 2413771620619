import React from 'react';
import { Typography } from '@material-ui/core';

import Table from './Table';
import ButtonComponent from '../../Components/Elements/Button/ButtonComponent';
import { CLIENT_STATUS_LIST } from '../../../constants';

const CustomerList = ({ data, onClick }) => {
  const tableHeader = [
    {
      id: 'head_1',
      label: 'ID',
      componentRender: ({ id }) => <Typography align="left">{id}</Typography>,
    },
    {
      id: 'head_2',
      label: 'Client ID',
      componentRender: ({ clientId }) => <Typography align="left">{clientId}</Typography>,
    },
    {
      id: 'head_3',
      label: 'ФИО',
      componentRender: ({ firstName, middleName, lastName }) => (
        <Typography align="left">
          {lastName} {firstName} {middleName}
        </Typography>
      ),
    },
    {
      id: 'head_4',
      label: 'Должность',
      componentRender: ({ position }) => <Typography align="left">{position}</Typography>,
    },
    {
      id: 'head_5',
      label: 'ИНН',
      componentRender: ({ inn }) => <Typography align="left">{inn}</Typography>,
    },
    {
      id: 'head_6',
      label: 'Организация',
      componentRender: ({ companyName }) => <Typography align="left">{companyName}</Typography>,
    },
    {
      id: 'head_7',
      label: 'Скидка',
      componentRender: ({ discount }) => <Typography align="left">{discount}%</Typography>,
    },
    {
      id: 'head_8',
      label: 'Город',
      componentRender: ({ legalLocation }) => <Typography align="left">{legalLocation.city}</Typography>,
    },
    {
      id: 'head_9',
      label: 'Статус',
      componentRender: ({ accepted }) => <Typography align="left">{accepted ? CLIENT_STATUS_LIST.accepted : CLIENT_STATUS_LIST.notAccepted}</Typography>,
    },
    {
      id: 'head_10',
      label: '',
      componentRender: ({ id }) => (
        <ButtonComponent variant="text" onClick={() => onClick(id)}>
          Открыть
        </ButtonComponent>
      ),
    },
  ];

  return <Table data={data} title="Список клиентов" tableHeader={tableHeader} />;
};

export default CustomerList;
