import React from 'react';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CardMedia from '@material-ui/core/CardMedia';
import Slide from '@material-ui/core/Slide';

import LoginContainer from './LoginContainer/';
import logo from '../../../assets/ETRA_ADMIN_logo.png';
import commonStyles from '../../../commonUtils/commonStyles';

function AuthForm() {
  return (
    <Grid container>
      <Slide direction="right" in={true} mountOnEnter unmountOnExit>
        <Paper elevation={3} style={getStyles().paperLogin}>
          <Grid container>
            <Grid item style={getStyles().header}>
              <CardMedia component="img" image={logo} style={getStyles().logoImage} />
            </Grid>
          </Grid>
          <LoginContainer />
        </Paper>
      </Slide>
    </Grid>
  );
}

const getStyles = () => {
  const styles = {
    logoImage: {
      width: '360px',
    },
    paperLogin: {
      margin: 'auto',
      maxWidth: 500,
      minWidth: 500,
      zIndex: 1,
      position: 'relative',
    },
    paperRegister: {
      width: 790,
      margin: 'auto',
      zIndex: 1,
      position: 'relative',
    },
    paperRegisterSmall: {
      maxWidth: 500,
      minWidth: 500,
      margin: 'auto',
      zIndex: 1,
      position: 'relative',
    },
    header: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      padding: '30px 45px',
    },
    tabs: {
      background: commonStyles.color.blue,
      width: '100%',
    },
  };
  return styles;
};

export default AuthForm;
