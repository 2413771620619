import React from 'react';
import { TableContainer, Box, Paper, Typography } from '@material-ui/core';
import commonStyles from '../../../commonUtils/commonStyles';
import TableComponent from '../../Components/Elements/Table/TableComponent';

const title = 'Каталоги и буклеты';

const CatalogTable = ({ data, stylesContext, deletePDF, shiftUp, shiftDown }) => {
  const { table, actions, pointer, link, serial } = getStyles();
  const { titleText } = stylesContext;

  const showDownArrow = (props) => {
    const index = data.findIndex((item) => item.id === props.id);
    if (index) {
      return (
        <i style={{ ...pointer, marginBottom: '15px' }} onClick={() => shiftUp(props)}>
          &#x2191;
        </i>
      );
    }
    return null;
  };

  const showUpArrow = (props) => {
    const index = data.findIndex((item) => item.id === props.id);
    if (index !== data.length - 1) {
      return (
        <i style={{ ...pointer }} onClick={() => shiftDown(props)}>
          &#x2193;
        </i>
      );
    }
    return null;
  };

  const tableHeader = [
    {
      id: 'head_1',
      label: '№',
      width: '90px',
      componentRender: (props) => (
        <Box display="flex" alignItems="center">
          <Typography style={{ ...serial }}>{props.number}</Typography>
          <Box display="flex" flexDirection="column">
            {showDownArrow(props)}
            {showUpArrow(props)}
          </Box>
        </Box>
      ),
    },
    {
      id: 'head_2',
      label: 'Раздел',
      width: '200px',
      align: 'center',
      componentRender: ({ section, preview, id }) => (
        <Box display="flex" alignItems="center">
          <img style={{ maxWidth: '60px', marginRight: '20px' }} src={preview} alt="" />
          <Typography align="left">{section}</Typography>
        </Box>
      ),
    },
    {
      id: 'head_3',
      label: 'Название документа',
      componentRender: ({ name, id }) => <Typography align="left">{name}</Typography>,
    },
    {
      id: 'head_4',
      label: '',
      componentRender: ({ id, document }) => (
        <div style={{ ...actions }}>
          <Typography
            style={{
              ...commonStyles.text.robotoRegular16,
              ...actions.download,
              ...pointer,
            }}
          >
            <a className="file-link" style={link} href={document} download>
              Скачать
            </a>
          </Typography>
          <Typography
            style={{
              ...commonStyles.text.robotoRegular16,
              ...actions.delete,
              ...pointer,
            }}
            onClick={() => deletePDF(id)}
          >
            Удалить
          </Typography>
        </div>
      ),
    },
  ];

  return (
    <TableContainer style={table} component={Paper} className="table-container">
      <Typography
        style={{
          ...commonStyles.text.robotoRegular24,
          ...titleText,
        }}
      >
        {title}
      </Typography>

      <TableComponent headCells={tableHeader} rows={data} />
    </TableContainer>
  );
};

const getStyles = () => {
  return {
    table: {
      marginTop: '20px',
      marginBottom: '100px',
      boxShadow: '0 0 4px rgba(0, 0, 0, 0.12), 0 1px 5px rgba(0, 0, 0, 0.2)',
    },
    actions: {
      download: {
        color: commonStyles.color.blue,
        marginBottom: '16px',
      },
      delete: {
        color: commonStyles.color.hotWater,
      },
    },
    pointer: {
      cursor: 'pointer',
    },
    link: {
      color: commonStyles.color.blue,
      textDecoration: 'none',
    },
    serial: {
      maxWidth: '65px',
      marginRight: '15px',
    },
  };
};

export default CatalogTable;
