// vvv Utility functions

//Auth

export const isValidToken = (date) => {
  const hour = 60 * 60000; //60 minutes for token timeout /* ms */
  const hourAgo = Date.now() - hour;

  return date > hourAgo;
};

export const resetToken = (setToastState) => {
  localStorage.removeItem('tokens');
  if (window.location.pathname !== '/') {
    window.location.href = '/';
    setToastState((prevState) => ({
      ...prevState,
      toastOpen: true,
      toastMessage: 'Время сессии истекло, пожалуйста зайдите снова!',
      toastStyle: 'error',
    }));
  }
};

export function validateEmail(mail) {
  const emailRule = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;

  if (emailRule.test(mail)) {
    return true;
  }
  return false;
}

export function validatePhone(phone) {
  const phoneRule = /^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/;

  if (phoneRule.test(phone)) {
    return true;
  }

  return false;
}

export function validatePassword(password) {
  /* The string must contain:
    - at least 1 lowercase alphabetical character
    - at least 1 numeric character
    - at least one special character
    - must be at least 8 characters
  */
  const passwordRule = /^(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$/;

  if (passwordRule.test(password)) {
    return true;
  }
  return false;
}

//Common

export function roundToDecimals(value, decimals) {
  return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
}

export function truncateDecimals(number, digits) {
  let multiplier = Math.pow(10, digits),
    adjustedNum = number * multiplier,
    truncatedNum = Math[adjustedNum < 0 ? 'ceil' : 'floor'](adjustedNum);

  return truncatedNum / multiplier;
}

export function getPropsByValueInObject(object, value) {
  let propsArr = [];
  let key = '';
  for (key in object) {
    //console.log('key: ' + key + ' value: ' + object[key]);
    if (String(object[key]) === String(value) || String(object[key]) === '') {
      //console.log('key 2: ' + key + ' value: ' + object[key]);
      propsArr.push(key);
    }
  }
  return propsArr;
}

export function trimStringToLength(string, length) {
  return string.substring(0, length);
}

export function validateText(str) {
  if (!str) return false;
  return Boolean(str.length > 1);
}

export function compareObjects(object1, object2) {
  //check if objects have properties with same value
  const keys1 = Object.keys(object1);
  //const keys2 = Object.keys(object2);
  /*
  if (keys1.length !== keys2.length) {
    return false;
  }
  */
  let propertiesEqual = false;
  for (let key of keys1) {
    if (object1[key] === object2[key]) {
      //return false;
      propertiesEqual = true;
    }
  }
  return propertiesEqual;
}

function dateUtils(number){
  if(Number(number) < 10){
    return `0${number}`
  }
  return number
}

export function formatDate(date, separator) {
  const newDate = new Date(date)
  const day = dateUtils(newDate.getDate())
  const month = dateUtils(newDate.getMonth())
  const year = newDate.getFullYear()
  return `${day}${separator}${month}${separator}${year}`
}
